import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingDialogComponent } from './onboarding-dialog/onboarding-dialog.component';
import {ShareModule} from "../share/share.module";
import {FormsModule} from "@angular/forms";
import {LottieComponent} from "ngx-lottie";
import {Button} from "primeng/button";
import {StepperModule} from "primeng/stepper";
import {ToggleButtonModule} from "primeng/togglebutton";
import {ProgressBarModule} from "primeng/progressbar";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { OnboardingEmployeeDialogComponent } from './onboarding-employee-dialog/onboarding-employee-dialog.component';



@NgModule({
  declarations: [OnboardingDialogComponent, OnboardingEmployeeDialogComponent],
  imports: [
    CommonModule,
    StepperModule,
    ShareModule,
    Button,
    ToggleButtonModule,
    FormsModule,
    LottieComponent,
    ProgressBarModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
  ],
})
export class OnboardingModule {}

