import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsvImporterComponent } from './csv-importer/csv-importer.component';
import {PrimeTemplate} from "primeng/api";
import {StepperModule} from "primeng/stepper";
import {TranslateModule} from "@ngx-translate/core";
import {ShareModule} from "../share/share.module";
import {DividerModule} from "primeng/divider";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [CsvImporterComponent],
  imports: [
    CommonModule,
    PrimeTemplate,
    StepperModule,
    TranslateModule,
    ShareModule,
    DividerModule,
    FormsModule,
  ],
})
export class ImporterModule {}
