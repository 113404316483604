import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { delay, forkJoin, mergeAll, Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import * as Sentry from '@sentry/angular';
import { UrlUtil } from '../../utils/url.util';
import { AppRepository } from '../../../data/repository/app.repository';

@Injectable()
export class PanelInterceptor implements HttpInterceptor {
  requests: any[] = [];
  flag: boolean = true;

  token: string | null;
  empId: string | null;
  domain: string | null;
  appId: string | null;

  constructor(
    private router: Router,
    private api: ApiService,
    private appRepo: AppRepository,
    private urlUtils: UrlUtil,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = this.addTokenHeader(request);
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.error.statusCode == 401) {
          this.requests.push(request);
          if (this.flag) {
            this.flag = false;
            return this.api.refreshToken().pipe(
              delay(500),
              switchMap((response: any) => {
                this.flag = true;
                this.token = response.data.accessToken;
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);

                // Map each request to an observable and collect them in an array
                const observables: Observable<HttpEvent<any>>[] = this.requests.map((req) =>
                  next.handle(this.addTokenHeader(req)),
                );

                // this.rxjs.broadcastLottiLoading(true);
                if (this.requests.length > 1) window.location.reload();
                this.requests = [];

                // Use mergeAll to merge the array of observables into a single observable
                // return forkJoin(observables).pipe(mergeAll());
                return forkJoin(observables).pipe(mergeAll());
              }),
              catchError(() => {
                localStorage.clear();
                sessionStorage.clear();

                setTimeout(() => {
                  this.router.navigate(['/']).then();
                }, 800);

                return throwError(null);
              }),
            );
          }
        } else {
          Sentry.captureMessage(`${error.error.statusCode}:${error.url}`, {
            extra: {
              url: error.url,
              status: error.status,
              message: error.message,
            },
          });
        }
        return throwError(error.error.statusCode == 401 ? null : error);
      }),
    );
  }

  addTokenHeader(request: HttpRequest<any>) {
    let appPath = this.urlUtils.getAppPath();
    this.token = this.token || localStorage.getItem('token');
    this.domain = this.domain || localStorage.getItem('domain');
    this.empId = this.empId || localStorage.getItem('empId');
    let headers = request.headers;
    if (this.token) headers = headers.set('Authorization', `Bearer ${this.token}`);
    if (this.domain) headers = headers.set('sub-domain', this.domain || '');
    if (this.empId) headers = headers.set('X-EMP-ID', this.empId || '');
    if (appPath) {
      let appId = this.appRepo.getAppIdByPath(appPath);
      if (appId) headers = headers.set('app-id', appId);
    }
    return request.clone({
      headers,
    });
  }
}
